<template>
  <div class="survey-complete-view">
    <v-row>
      <v-col cols="12">
        <p class="tw-text-light-grey">
          Your responses have been submitted! Let's head over to the Trip Hub to
          view the details.
        </p>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-btn
          class="tw-mx-auto tw-w-3/4 j-shadow-override tw-tracking-normal"
          color="primary"
          rounded
          x-large
          :to="{ name: 'TripViewRoute', params: { id: tripId } }"
          >Go to Trip Hub</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "SurveyCompleteView",

  computed: {
    tripId() {
      return this.$route.params.id;
    },
  },

  beforeMount() {
    this.$store.commit("meta/setHeader", {
      defaultHeaderDisabled: false,
      pageTitle: "Let's Jetty!",
      bgColor: "#203848",
      fontColor: "#E6FFA1",
      tagline: null,
      iconOne: null,
      iconTwo: null,
      tripHubButton: true,
      tripHubButtonId: this.tripId,
    });
  },
};
</script>
