<template>
  <div
    class="tw-h-full tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-chartreuse tw-p-8"
  >
    <div>
      <div>
        <img
          src="@/assets/svgs/profile/email.svg"
          alt="Envelope"
          class="tw-mx-auto tw-mb-4 tw-w-36 tw-h-36"
        />
        <h2
          class="tw-text-lg tw-font-semibold tw-text-dark-green tw-w-3/6 tw-mx-auto"
        >
          You did it! Now let's invite the crew.
        </h2>
        <p
          class="tw-mb-0 tw-text-dark-green tw-text-sm tw-leading-22 tw-w-3/6 tw-mx-auto"
        >
          Your survey will be attached to your trip invitation. Click create
          invitation to get started.
        </p>
        <v-btn
          rounded
          block
          color="secondary"
          x-large
          class="tw-mt-40 tw-mb-6 tw-tracking-normal"
          :to="{ name: 'InviteCreateRoute', params: { id: tripId } }"
          >Create Invitation</v-btn
        >
        <router-link
          class="tw-text-dark-green tw-border-b tw-border-solid tw-border-dark-green tw-font-bold tw-inline-block"
          :to="{ name: 'TripsRoute', params: { id: tripId } }"
        >
          Skip and Create Later
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tripId: this.$route.params.id,
    };
  },
};
</script>
